/* SignIn.css */
body {
  background-color: #f8f9fa;
}

.card {
  border-radius: 10px;
}

/* Navbar.css */
.navbar {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
}

.nav-link {
  font-size: 16px;
  color: #007bff;
  transition: color 0.2s ease-in-out;
}

.nav-link:hover {
  color: #0056b3;
}

.navbar-brand {
  font-size: 22px;
  font-weight: bold;
  color: #007bff;
}

.navbar-brand:hover {
  color: #0056b3;
}

.nav-link.active {
  font-weight: bold;   /* Bold font for active link */
  color: blue;         /* Change color as desired */
}

/* SettingsPage.css */

/* Blur the main container except for the modal */
.blur-background > :not(.modal) {
  filter: blur(4px);
  transition: filter 0.3s ease;
}

/* Styling for error validation */
.is-invalid {
  border-color: #dc3545 !important;
}

/* Styling for modal close button */
.modal-header .btn-close {
  padding: 0;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  opacity: 0.6;
}

.modal-header .btn-close:hover {
  opacity: 1;
}

.green-dot {
  width: 10px;              /* Width of the dot */
  height: 10px;             /* Height of the dot */
  background-color: green;  /* Green color */
  border-radius: 50%;       /* Makes it circular */
  display: inline-block;    /* Ensures it stays inline */
}